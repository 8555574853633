<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main admin-col-bg flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col whole_000">
				<div class="coll-post whole_fff">
					<router-link to="">{{$t('meun13_2')}}</router-link> > <router-link to="">{{$t('clLink2')}}</router-link>
				</div>
				<div class="coll3_box whole_fff">
					<div class="coll3-box">
						<div class="coll3-tt">Bank Transfer</div>
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="coll3-form">
							<el-form-item prop="value">
								<div class="coll3-group coll3_sel">
									<div class="coll3_tt">Bank Account No</div>
									<el-select v-model="ruleForm.value" :placeholder="$t('placeholder3')">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</div>
							</el-form-item>
							<el-form-item prop="name">
								<div class="coll3-group">
									<div class="coll3_tt">{{$t('name')}}</div>
									<el-input v-model="ruleForm.name" :placeholder="$t('clplaceholder1')"></el-input>
								</div>
							</el-form-item>
							<div class="coll3-group coll4_upload">
								<div class="coll3_tt">QR code({{$t('clTxt6')}})</div>
								<div class="coll4_up_in">
									<el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
										:on-preview="handlePictureCardPreview" :on-remove="handleRemove">
										<i class="iconfont icon-upload"></i>
										<span>{{$t('upload')}}</span>
									</el-upload>
									<el-dialog :visible.sync="dialogVisible">
										<img width="100%" :src="dialogImageUrl" alt="">
									</el-dialog>
								</div>
								<div class="coll4_up_ti">{{$t('uploadTip')}}</div>
							</div>
							<div class="coll3-group coll3_cozy">
								<div class="coll3_cozy_tt">{{$t('czTxt9')}}</div>
								<p class="line-2">{{$t('cltip3')}}</p>
							</div>
							<div class="coll3_btn flex flex-ac flex-between">
								<el-button class="btn btn_reset" type="primary">{{$t('reset')}}</el-button>
								<el-button class="btn" type="primary">{{$t('save')}}</el-button>
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				options: [{
					value: '选项1',
					label: 'Bank Account No1'
				}, {
					value: '选项2',
					label: 'Bank Account No2'
				}],
				ruleForm:{
					value: '',
					name: '',
				},
				dialogImageUrl: '',
				dialogVisible: false
			};
		},
		computed:{
			rules(){
				return {
					name: [{
							required: true,
							message: this.$t('clplaceholder1')
						}
					]
				}
			}
		},
		methods: {
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			}
		}
	}
</script>
